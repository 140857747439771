import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <div className="splash-container" style={{background: '#77878B'}}>
      <h1 style={{color: 'white', textAlign: 'center'}}>NOT FOUND</h1>
      <p style={{color: 'white', textAlign: 'center'}}>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
